@import '~vuetify/src/styles/styles.sass';












































//@import '@design';
Layout {
  text-align: center;
}
